$(document).ready(function() {
	$("#main-carousel").owlCarousel({
	  items: 1,
	  loop: false,
	  nav: false,
	  dots: false,
	  autoplay: false,
	  autoplayTimeout: 4000,
	  autoplayHoverPause: true,
	  touchDrag: false,
	  mouseDrag: false
	});

  var owl = $(".gallery");
 
  owl.owlCarousel({
    nav:true,
    responsive:{
        0:{
            items:1
        },
        768:{
            items:2
        },
        992:{
            items:2
        },
        1260:{
            items:3
        }
    }
  });
 
  // Custom Navigation Events
  $(".next").click(function(){
    owl.trigger('next.owl.carousel');
  })
  $(".prev").click(function(){
    owl.trigger('prev.owl.carousel');
  })

	var $navbar = $(".header"),
	headerHeight = $(".header").outerHeight(),
	$navbarLink = $(".navbar-nav li a");
	
	$navbar.affix({ 
	    offset: headerHeight
	});

	$('body').scrollspy({ 
		target: '.header',
	    offset: (headerHeight + 61)
	});

	$( window ).resize(function() {
		headerHeight = $(".header").outerHeight();

		$navbar.data('bs.affix').options.offset = headerHeight;
		$('body').data('bs.scrollspy').options.offset = (headerHeight + 61);
	});	


  // Set hash on scrollspy fire
  $(window).on('activate.bs.scrollspy', function () {
    hash = $(".navbar-nav li.active a").attr("href");
    $(".navbar-nav a:focus").blur();
    
    history.replaceState(undefined, undefined, hash);	
  });

$(window).scroll(function(){
    if($('body').scrollTop() == 0) {
    	//history.pushState("", document.title, window.location.pathname);
    	//document.location.hash="#";
		//return false; // Cancel any event from this point forward
    }
});
  $navbarLink.click(function(event) {
    event.preventDefault();

    hash = $(this).attr('href');
    sectionOffset = $(hash).offset().top;
    sectionOffset = sectionOffset - (headerHeight + 57);

    $( window ).resize(function() {
      sectionOffset = $(hash).offset().top;
      sectionOffset = sectionOffset - (headerHeight + 57);
    }); 

    if(sectionOffset < 0) sectionOffset = 0;
    $('html, body').animate({
      scrollTop: sectionOffset
    }, 850);

	if($(document).scrollTop() != sectionOffset) $(".navbar li.active").removeClass('active');
	    if(history.pushState) {
	      history.pushState(null, null, hash);
	    }
	    else {
	      location.hash = hash;
	    }
  });

    $(function () {
            $('.navbar-collapse ul li a:not(.dropdown-toggle)').bind('click touchstart', function () {
                    $('.navbar-toggle:visible').click();
            });
    });

   $(".fancybox").fancybox();
    $(".fancybox-thumbs").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        openEffect: 'elastic',
        openSpeed: 150,
        closeEffect: 'elastic',
        closeSpeed: 150,
        closeClick: true,
        helpers: {
            title: {
                type: 'inside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });

});